import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css"; /// This is imported in index.scss
import "bootstrap/dist/js/bootstrap";
import "./index.scss";
import AppState from "./context/AppState";
import CartState from "./context/cart_context/CartState";
import './i18n';
import "react-loading-skeleton/dist/skeleton.css";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <AppState>
      <CartState>
        <App />
      </CartState>
    </AppState>
  </React.StrictMode>,
  document.getElementById("root")
);
