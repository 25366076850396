import {GET} from "../utils/https";
import {getCookie} from "../utils/cookies";

async function getCurrentUser({setCurrentUser}) {
    const id = getCookie("kerux-user-id") // localStorage.getItem("userId");

    try {
        const {json} = await GET(`/api/users/me/${id}`);

        setCurrentUser(json.data);
    } catch (err) {
        console.log(err);
    }
}

export default getCurrentUser;
