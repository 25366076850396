import "./SingleCartProduct.css";
import useSingleCartProduct from "../../../hooks/useSingleCartProduct";
//import DeleteIcone from "../../../img/trash-alt-regular.svg";
import {DeleteIcon} from "../../../Assets/icons";

import {useTranslation} from 'react-i18next'
export default function SingleCartProduct({product}) {
  const {
    deleteOfCartAndTotalCostHandler,
    increaseQuantityAndActualizeTotalCost,
    decreaseQuantityAndActualizeTotalCost,
  } = useSingleCartProduct(product._id);
  const { t } = useTranslation();
  return (
    <div className="cart-product">
      <div className="product-row">
        <div className="product-column start">
          <div className="product-name">{product.name}</div>
          <div className="product-number">{product.size}</div>
          <img
            className="product-img"
            src={product.img}
            alt={product.name}
          />
        </div>
        <div className="product-column end">
          <DeleteIcon
            className="icon"
            height={25}
            fill="#FFF"
            onClick={() => deleteOfCartAndTotalCostHandler(product._id)}
          />

          <div className="counter">
            <button
              className={`counter-btn counter-btn_- ${
                product.quantity === 1 ? "quantityOne" : ""
              }`}
              onClick={() => decreaseQuantityAndActualizeTotalCost()}
            >
              -
            </button>
            <span data-testid="quantity" className="number">
              {product.quantity}
            </span>
            <button
              className="counter-btn counter-btn_plus"
              onClick={() => increaseQuantityAndActualizeTotalCost()}
            >
              +
            </button>
          </div>
          <div className="price">{product.quantity * product.price} DA</div>
        </div>
      </div>
    </div>
  );
}
