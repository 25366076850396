import useDashboarOrderDetails from "../../../hooks/useDashboarOrderDetails";
import AdminOrderStateChart from "./AdminOrderStateChart";
import {Fragment} from "react";
import {DeliveryCoordinatesSections, OrderDetailsTable} from "../account/UserOrderDetailsPage";
import {Button, Container} from "react-bootstrap";
import DashboardSectionContainer from "./common/DashboardSectionContainer";
import {BackIcon} from "../../../Assets/icons";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function OrderDetails() {
  const { t } = useTranslation();
  const {thisOrder, isRefreshing, isLoading, setIsRefreshing} = useDashboarOrderDetails();

  
  if (isLoading || !thisOrder) return null;

  return (
    <DashboardSectionContainer
      backLink={<Link to="/dashboard/orders" className="text-decoration-none">
        <Button size="sm" className="px-2 me-1">
          <BackIcon fill="white" width={14}/>
        </Button>
        {t("Retour")}
      </Link>}
      title={t("Détails De La Commande")}>

      {isLoading ? null : (
        <Fragment>

          <div className="pageTitle mt-3">
           {t(" N° De Commande")} <span className="orderId">{thisOrder?.orderID}</span>
          </div>


          <Container className="my-4 mx-auto" style={{maxWidth: 700}}>
            <AdminOrderStateChart
              states={thisOrder?.states}
              orderId={thisOrder?._id}
              makeRefresh={setIsRefreshing}
              refreshState={isRefreshing}
            />
          </Container>


          <DeliveryCoordinatesSections order={thisOrder}/>

          <Container className="p-0">
            <div className="sectionTitle">{t("Détails De Commande")}</div>
            <OrderDetailsTable order={thisOrder}/>
          </Container>

        </Fragment>
      )}
    </DashboardSectionContainer>
  );
}
