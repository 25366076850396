import { GET } from "../utils/https";

export default async function getDeliveryDistrictsAPI(setAllDeliveryDistricts) {
  try {
    const { json } = await GET("/api/districtDelivery");
    setAllDeliveryDistricts(json.data);
  } catch (err) {
    console.log(err);
  }
}
