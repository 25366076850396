import {GET} from "../utils/https";

export default async function getBannerImagesAPI(setAllBanners) {
    try {
        const {json} = await GET("/api/banners");
        setAllBanners(json.data);
    } catch (err) {
        console.log(err);
    }
}
