import {Button, Offcanvas} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {
  BasketIcon,
  DashboardIcon,
  EmailIcon,
  HamburgerMenuIcon,
  HomeIcon,
  LogoutIcon,
  MenuIcon,
  ProductsIcon,
  PromotionsIcon,
  StarIcon,
  UsersIcon,
  UserIcon,
} from "../../../Assets/icons";
import useHeader from "../../../hooks/useHeader";
import useMyProfile from "../../../hooks/useMyProfile";
import {KeruxLogo} from "../../../img/";
import {useState ,useEffect} from 'react'
import {useTranslation} from 'react-i18next'
const StyledLogo = styled.img`
  display: block;
  width: 50%;
  margin: auto;
`;

const StyledOffcanvas = styled(Offcanvas)`
  width: 20% !important;

  @media (max-width: 1200px) {
    // xl
    width: 25% !important;
  }

  @media (max-width: 992px) {
    // lg
    width: 30% !important;
  }

  @media (max-width: 576px) {
    width: 65% !important;
  }
`;

const StyledOffcanvasBody = styled(Offcanvas.Body)`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbbbb;
    border-radius: 48px;
    outline: 1px solid slategrey;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    fill: white;
    stroke: white;
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.highlight};

    svg {
      fill: ${(props) => props.theme.colors.highlight};
      stroke: ${(props) => props.theme.colors.highlight};
    }
  }
`;

export default function KeruxSidebar(props) {
  const {isLogin, isAdmin, isModerator} = useHeader();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'fr');


  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
      if (i18n.language === 'ar') {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      }  
  }, [selectedLanguage]);
  
    const handleLanguageChange = (event) => {
      const itemValue = event.target.value;
      async function setLang() {
        setSelectedLanguage(itemValue);
        i18n.changeLanguage(itemValue);
        localStorage.setItem('selectedLanguage', itemValue); 
      }
      setLang()
    };

  const {currentUser} = useMyProfile();

  const publicRoutes = [
    {
      to: "/",
      text: t("Accueil"),
      icon: HomeIcon,
      authOnly: false,
    },
    {
      to: "/menu",
      text: t("Notre Menu"),
      icon: MenuIcon,
      authOnly: false,
    },
    {
      to: "/contact",
      text: t("Contact"),
      icon: EmailIcon,
      authOnly: false,
    },
    {
      to: "/myAccount/myOrders",
      text: t("Mes commandes"),
      icon: BasketIcon,
      authOnly: true,
    },
  ];

  const dashboardRoutes = [
    {
      to: "/dashboard/orders",
      text: t("Tableau de Bord"),
      icon: DashboardIcon,
    },
    {
      to: "/dashboard/myProducts",
      text: t("Nos Produits"),
      icon: ProductsIcon,
    },
    {
      to: "/dashboard/categories",
      text: t("Nos Catégories"),
      icon: StarIcon,
    },
    {
      to: "/dashboard/promo",
      text: t("Codes Réductions"),
      icon: PromotionsIcon,
    },
  ];
  return (
    <>
      <Button size="sm" onClick={props.handelNavClick} variant="" className="ms-2 pt-0">
        <HamburgerMenuIcon height={14} width={21}/>
      </Button>

      <StyledOffcanvas className="bg-primary text-white" show={props.navIsOpened} onHide={props.closeNav}>
        <Offcanvas.Header>
          <Offcanvas.Title className="mx-auto">
            <StyledLogo src={KeruxLogo} alt="Kerux"/>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <StyledOffcanvasBody className="px-0">
          <nav className="nav nav-pills flex-column mb-auto ps-4">
            {publicRoutes.map((route, i) => {
              if (!isLogin && route.authOnly) return null;

              const IconComponent = route.icon;
              return (
                <StyledNavLink exact to={route.to} aria-current="page" className="my-2" key={route.to + i}>
                  <IconComponent
                    fill=""
                    className="mx-2"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  {route.text}
                </StyledNavLink>
              );
            })}
           <select value={selectedLanguage} onChange={handleLanguageChange} className="mx-2" style={{ width: '90%' }}>
        {Object.keys(i18n.options.resources || [])
          .sort()
          .map((lang) => {
            const nameGenerator = new Intl.DisplayNames(['fr'], { type: 'language' });
            const displayName = nameGenerator.of(lang);

            return (
              <option key={lang} value={lang}>
                {displayName}
              </option>
            );
          })}
      </select>
          </nav>

          {isAdmin || isModerator ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                {dashboardRoutes.map((route, i) => {
                  const IconComponent = route.icon;
                  return (
                    <StyledNavLink exact to={route.to} key={route.to + i} aria-current="page" className="my-2">
                      <IconComponent
                        fill=""
                        className="mx-2"
                        height={20}
                        width={20}
                        style={{verticalAlign: "-0.125em"}}
                      />
                      {route.text}
                    </StyledNavLink>
                  );
                })}
              </nav>
            </>
          ) : (
            <></>
          )}

          {isAdmin ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                <StyledNavLink exact to="/dashboard/users" key={"/dashboard/users"} aria-current="page" className="my-2">
                  <UsersIcon className="mx-2" height={20} width={20} style={{verticalAlign: "-0.125em"}}/>
                  {t("Nos Utilisateurs")}
                </StyledNavLink>
              </nav>
            </>
          ) : (
            <></>
          )}

          {isLogin ? (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4 mx-2">
                <StyledNavLink exact to="/myAccount/myProfile" key={"/myAccount/myProfile"} aria-current="page" className="my-2">
                  
                  <span>
                    <strong>{currentUser.name}</strong>
                  </span>
                </StyledNavLink>

                <StyledNavLink key={"logout"} to="/authentication/logout" aria-current="page" className="my-2">
                  <LogoutIcon
                    stroke="white"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  {t("Se déconnecter")}
                </StyledNavLink>
              </nav>
            </>
          ) : (
            <>
              <hr className="bg-warning border-2 border-top border-warning"/>
              <nav className="nav nav-pills flex-column mb-auto ps-4">
                <StyledNavLink exact to="/authentication/login" aria-current="page" className="my-2">
                  <LogoutIcon
                    stroke="white"
                    className="mx-2"
                    height={20}
                    width={20}
                    style={{verticalAlign: "-0.125em"}}
                  />
                  {t("Se connecter")}
                </StyledNavLink>
              </nav>
            </>
          )}
        </StyledOffcanvasBody>
      </StyledOffcanvas>
    </>
  );
}
