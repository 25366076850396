import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {useStorage} from "../context/useStorage";
import UploadProfileAPI from "../API/UploadProfileAPI";
import {UserInfo} from "../types";

export default function useEditProfileForm() {
  const {setCurrentUser, setIsSuccessfullySend, currentUser} = useStorage();

  const [serverError, setServerError] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);

  const defaultsValues: {
    name: string;
    lastName: string;
    address: string;
    number: string;
    district: string;
  } = {
    name: currentUser.name?.split(" ")[0],
    lastName: currentUser.name?.split(" ")[1],
    address: currentUser.address,
    number: currentUser.number,
    district: currentUser.district,
  };

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: "onBlur",
  });

  async function onSubmit(data, e) {
    e.preventDefault();

    const name = data.userName?.toLowerCase(),
      lastName = data.userLastName?.toLowerCase(),
      district = data.userDistrict,
      address = data.userAddress;

    const info = {
      name,
      lastName,
      password: data.userPassword || null,
      newPassword: data.userNewPassword || null,
      number: data.userNumber,
      district,
      address,
    };

    await UploadProfileAPI({
      setFormIsLoading,
      setIsSuccessfullySend,
      setServerError,
      info,
      setCurrentUser,
      history,
    });
  }

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    serverError,
    setCurrentUser,
    formIsLoading,
    defaultsValues,
    isChangingPassword,
    setIsChangingPassword,
  };
}
