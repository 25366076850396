import styled from "styled-components";
import {Error404Icon} from "../../Assets/icons"

import {useTranslation} from 'react-i18next'
const Page = styled.section`
  padding: 15px;
  padding-top: 58px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  //background: linear-gradient(123deg, #ffa500, #fcba1c8f);
  & > div {
    & > h2 {
      width: max-content;
      margin: 0 auto;
    }

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    @media screen and (min-width: 600px) {
      font-size: 50px;
    }
  }
`;
const ErrorCode = styled.h2`
  font-size: 60px;
  @media screen and (min-width: 600px) {
    font-size: 100px;
  }
`;
export default function NotFound404Page() {
  
const { t } = useTranslation();
    return (
        <Page>
            <div>
                <Error404Icon/>
                <h2 className="text-primary">{t("Page non trouvée")}</h2>
            </div>
        </Page>
    );
}
