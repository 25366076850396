import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {FacebookIcon, InstagramIcon, PhoneIcon, TikTokIcon} from "../../../Assets/icons";
//import SocialsMenu from "./SocialsMenu";
import {KeruxNavbar} from "./KeruxNavbar";
import './style.css'
import {useEffect, useState} from "react";
import {GET} from "../../../utils/https";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import {useTranslation} from 'react-i18next'
const StyledHeader = styled.header`
  /* position: fixed; */
  box-sizing: border-box;
  width: 100vw;
  padding: 0 5px;
  margin: 15px 0 0 0;
  height: 100px;
  /* z-index: 999; */
  background-color: #fff;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* border-bottom: 1px solid #1111; */
`;

export default function Header() {
  /*
  let subscribe = false;

  if (currentUser?.profileState !== "complited") {
    subscribe = true;
    return subscribe;
  }*/
  /*
  const subscribeFunction = () => {
    if (currentUser?.profileState !== "complited") {
      return alert(
        "Vous devez remplir votre profil de compte afin de passer la commande"
      );
    } else {
      return alert("Mamay");
    }
  };
*/

const [promos, setPromos] = useState([]);
  const [codes, setCodes] = useState([]);
  const { t } = useTranslation();
  const urlPromoCode = "/api/promos";

  const getAllPromoCode = async () => {
    try {
      const { response, json } = await GET(urlPromoCode);
      const allPromoCode = json.data;
      const getCodes = allPromoCode.map(promo => promo.promo_code);
      setPromos(allPromoCode);
      setCodes(getCodes); // This will trigger a re-render, but only once
    } catch (error) {
      console.error("Error: Kayen mochkil");
    }
  };

  const verifyCodes = async (promoCodes) => {
    for (const code of promoCodes) {
      const vrfPromocode = `/api/promos/vrf?promo_code=${code}`;
      try {
        const { response, json } = await GET(vrfPromocode);
        const vrfCode = json.successful;
      } catch (error) {
        console.error(`Error: Can't verify promo code ${code}`);
      }
    }
  };

  useEffect(() => {
    getAllPromoCode();
  }, []); 

  useEffect(() => {
    if (codes.length > 0) {
      verifyCodes(codes);
    }
  }, [codes]);

  
  const activePromos = promos?.filter(codes => codes?.active) || [];


  return (
    <StyledHeader className="head-style">
      <Container className="p-0">
      <Row className="promo-s">
      <Slider
              infinite={activePromos.length > 1 && true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
            >
          {promos?.map((codes, index) => {
            if (!codes?.active) {
              return null;
            }
            return (
              <div key={index} className="promo-container mt-0 mb-2" >
                <div style={{paddingLeft: 10, paddingRight: 10}} className="promo-text">
                  <p className="code-label">{t("Réduction avec le Code Promo")}</p>
                  <div className="promo-code move">
                    {codes?.promo_code}
                  </div>
                  
                </div>
                <div className="promo-image">
                  <p>{codes.pourcentage}%</p>
                </div>
              </div>
              )
            })}
            </Slider>
        </Row>

        <Row>
          <Col xs="3" className="d-flex d-lg-block justify-content-between">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61561299069594">
              <FacebookIcon height={20} className="mx-1 fill-primary"/>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/keruxfoods ">
              <InstagramIcon height={20} className="mx-1 fill-primary"/>
            </a>
            <a target="_blank" rel="noreferrer" href="https://tiktok.com/@keruxfoods">
              <TikTokIcon height={20} className="mx-1 fill-primary"/>
            </a>
            {/* <SocialMediaIcon src={FacebookIcon} alt="Fb" />
            <SocialMediaIcon src={InstagramIcon} alt="Ig" />
            <SocialMediaIcon src={TwitterIcon} alt="Twitter" /> */}
          </Col>
          <Col xs={5} className="text-end move promo-l" style={{marginTop: -8}}>
            <Slider
              infinite={activePromos.length > 1 && true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
            >
              {promos?.map((codes, index) => {
                if (!codes?.active) {
                  return null;
                }
                return (
                  <div key={index} className="promo-container mt-0 mb-2">
                    <div className="promo-text">
                      <p className="code-label">{t("Réduction avec le Code Promo")}</p>
                      <div className="promo-code">
                        {codes?.promo_code}
                      </div>
                    </div>
                    <div className="promo-image">
                      <p>{codes.pourcentage}%</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </Col>

          <Col xs="4" className="mb-1">
            <Container className="d-flex flex-row-reverse align-items-center">
              <span className="text-primary ms-2">
                <PhoneIcon width={16} className="fill-primary me-1"/>
                <strong className="d-none d-lg-inline fw-semi-bold">0670277680 | 0550319312</strong>
              </span>
              <Link to="/" className="me-2 text-decoration-none fw-bolder text-primary">
                {/*<b>العربية</b>*/}
              </Link>
            </Container>
          </Col>
        </Row>
        <Row>
          <KeruxNavbar/>
        </Row>
      </Container>
    </StyledHeader>
  );
}

