import useAdminOrderStateChart from "../../../hooks/useAdminOrderStateChart";
import { KeruxStepper } from "../../base/stepper/KeruxStepper";

import { useTranslation } from 'react-i18next';

export default function AdminOrderStateChart({
  states,
  orderId,
  makeRefresh,
  refreshState,
}) {
  const { handleConfirmation, activeStateIndex } = useAdminOrderStateChart({
    orderId,
    makeRefresh,
    refreshState,
    states,
  });

  const { t } = useTranslation();
  const steps = (states || []).map((state, i) => ({
    title: state.name,
    text: state.confirmed ? (
      <>
        {new Date(state.date).toLocaleDateString()}
        <br />
        <span className="text-muted">
          {new Date(state.date).toLocaleTimeString()}
        </span>
      </>
    ) : i == activeStateIndex ? (
      t("En cours...")
    ) : (
      ""
    ),
    completed: state.confirmed,
  }));

  return (
    <KeruxStepper
      steps={steps}
      activeIndex={activeStateIndex}
      onActiveClick={(state) => handleConfirmation(state.title)}
    />
  );
}
