import {useEffect, useState} from "react";
import {CategoryInfo} from "../types";

export default function useFetchProducts({
                                           category,
                                           title = "",
                                           activeProducts = true
                                         }: { category: CategoryInfo, title?: string, activeProducts: boolean }) {

  const fetchSize = 4;

  const [sorting, setSorting] = useState("-createdAt");
  const [sizeLimit, setSizeLimit] = useState(fetchSize)
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [currentQuery, setCurrentQuery] = useState(category + sorting);

  let query;

  query = new URLSearchParams();
  query.append("active", activeProducts);
  query.append("sort", sorting);
  query.append("page", page);
  query.append("limit", fetchSize);

  if (category)
    query.append("category", category.name);

  const loadMore = () => {
    setSizeLimit(sizeLimit + fetchSize);
    setPage(page + 1)
  }

  const showLoadMore = () => {
    return page < maxPage;
  }

  const resetPagination = () => {
    setProducts([]);
    setSizeLimit(fetchSize);
    setCurrentQuery(category + sorting);
    setPage(1);
    setMaxPage(1);
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setIsLoading(true);

    if (currentQuery != category + sorting) {
      resetPagination();
    }

    const fetchProducts = async () => {
      try {
        let res = await fetch(`/api/products?${query}`, {signal});
        let json = await res.json();

        if (currentQuery != category + sorting) {
          setProducts(json.data);
          setSizeLimit(fetchSize);
        } else {
          setProducts([
            ...products,
            ...json.data
          ]);
        }

        let total = parseInt(json.total);

        setMaxPage(Math.ceil(total / fetchSize));

        // document.querySelector("body").scrollTo(0, 100);
        setIsLoading(false);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Fetch Canseled: caught abort");
        } else {
          console.log(err);
        }
      }
    };
    fetchProducts();

    return () => {
      controller.abort();
    };
  }, [title, sorting, category, page]);

  return {
    isLoading,
    products,
    loadMore,
    sizeLimit,
    showLoadMore,
    resetPagination,
    sorting,
    setSorting,
  };
}
