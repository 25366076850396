import styled from "styled-components";
import {CheckedIcon, LoaderIcon} from "../../../Assets/icons";
import {STEP_BUTTON_SIZE, STEP_CONNECT_BORDER_HEIGHT} from "./KeruxStepper";
import { useTranslation } from 'react-i18next';

const StyledStepConnector = styled.div`
  top: ${(props) => `calc((${STEP_BUTTON_SIZE}px - ${STEP_CONNECT_BORDER_HEIGHT}px) / 2)`};
  left: ${(props) => `calc((-50% + ${STEP_BUTTON_SIZE / 2}px))`};
  right: ${(props) => `calc((50% + ${STEP_BUTTON_SIZE / 2}px))`};
  position: absolute;

  div {
    height: ${_ => STEP_CONNECT_BORDER_HEIGHT + "px"};
    background: ${({theme}) => `${theme.colors.primary}`};

    &.before-active {
      background: ${({theme}) => `transparent 
          linear-gradient(270deg, ${theme.colors.highlight} 0%, ${theme.colors.primary} 100%)
          0% 0% no-repeat padding-box`};
    }

    &.after-active {
      background: ${({theme}) => `${theme.colors.formWhite}`};
    }
  }
`

export function StepConnector({index, activeIndex}) {

  if (index === 0) return null;

  let className = "";
  if (activeIndex === index) className += " before-active"
  if (activeIndex < index) className += " after-active"

  return <StyledStepConnector>
    <div className={className}/>
  </StyledStepConnector>;
}

const StyledStep = styled.div`
  height: 100%;

  & .step-title {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${({theme}) => `${theme.colors.primary}`};

    &.active {
      color: ${({theme}) => `${theme.colors.highlight}`};
    }

    &.disabled {
      color: ${({theme}) => `${theme.colors.formWhite}`};
    }
  }

  & .step-text {
    font-weight: 500;
  }

  & .step-action {
    button {
      border: 0;
      outline: 0;
      padding: 0.25rem 8px;
      color: white;
      font-weight: 500;
      border-radius: 8px;
      background: ${({theme}) => theme.colors.info};
    }
  }

  @media (max-width: 992px) {
    & .step-title {
      font-size: 3.5vw;
    }
    
    & .step-text {
      font-size: 0.8rem;
    }

    & .step-action {
      button {
        span {
          display: none;
        }
      }
    }
  }
`

const StyledStepButton = styled.button<{ active?: boolean, isDisabled?: boolean }>`
  width: ${_ => STEP_BUTTON_SIZE + "px"};
  height: ${_ => STEP_BUTTON_SIZE + "px"};
  outline: none;
  border: 0;
  z-index: 2;
  background: ${({
                   active,
                   isDisabled,
                   theme
                 }) => active ? theme.colors.highlight : isDisabled ? theme.colors.formWhite : theme.colors.primary};
  color: white;
  border-radius: 50%;
`

export function Step({isActive, onClick, isDisabled, title = "", text = ""}) {
  const { t } = useTranslation();
  const state = isActive ? "active" : isDisabled ? "disabled" : "";
  return <StyledStep>
    <StyledStepButton disabled isDisabled={isDisabled} active={isActive}>
      {
        isActive ?
          <LoaderIcon stroke="white" height={STEP_BUTTON_SIZE / 2} width={STEP_BUTTON_SIZE / 2}/>
          :
          isDisabled ?
            <CheckedIcon fill="none" height={STEP_BUTTON_SIZE * 2 / 5} width={STEP_BUTTON_SIZE * 8 / 15}/> :
            <CheckedIcon fill="white" height={STEP_BUTTON_SIZE * 2 / 5} width={STEP_BUTTON_SIZE * 8 / 15}/>
      }
    </StyledStepButton>
    <div className={"step-title " + state}>{title}</div>
    {
      onClick ?
        <div className={"step-action"}>
          <button onClick={onClick}>
            <span>{t("Valider")}</span> <CheckedIcon width={20} fill="white"/>
          </button>
        </div>
        : <div className={"step-text " + state}>{text}</div>
    }
  </StyledStep>
}

export const StyledStepContainer = styled.div`
  flex: 1;
  position: relative;
`

export const StyledStepperContainer = styled.div`
  display: flex;
  justify-content: start;
  text-align: center;
`