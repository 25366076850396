import {getCookie} from "./cookies";

export function getToken() {
  return getCookie("kerux-user-token");
}

export function getCurrentUserId() {
  return getCookie("kerux-user-id");
}

async function POST(url, info, type = "JSON") {
  const token = getToken();
  const headers = new Headers();
  headers.append("Accept", "application/json");

  if (type !== "file") { // For multer, don't add a content-type header
    type === "formData"
      ? headers.append("Content-Type", "multipart/form-data")
      : headers.append("Content-Type", "application/json");
  }
  headers.append("Authorization", `Bearer ${token}`);

  const body = type === "JSON" ? JSON.stringify(info) : info;
  //console.log(FormData);

  const setting = {
    method: "POST",
    headers: headers,
    body,
  };

  const response = await fetch(url, setting);
  const json = await response.json();
  return {response, json};
}

async function PUT(url, info, type?: string) {
  const token = getToken();
  const headers = new Headers();

  headers.append("Authorization", `Bearer ${token}`);
  type === "formData"
    ? headers.append("Content-Type", "multipart/form-data")
    : headers.append("Content-Type", "application/json");

  const body = type === "formData" ? info : JSON.stringify(info);
  const setting = {
    method: "PUT",
    headers: headers,
    body,
  };
  const response = await fetch(url, setting);
  const json = await response.json();
  return {response, json};
}

async function DELETE(url) {
  const token = getToken();
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  const setting = {
    method: "DELETE",
    headers: headers,
  };
  const response = await fetch(url, setting);
  const json = await response.json();
  return {response, json};
}

async function GET(url, settings?: { controller?: AbortController }) {
  const token = getToken();
  const headers = new Headers();
  headers.append("Accept", "application/json");
  token && headers.append("Authorization", `Bearer ${token}`);
  const setting = {
    method: "GET",
    headers: headers,
    signal: settings?.controller?.signal
  };
  const response = await fetch(url, setting);
  const json = await response.json();
  return {response, json};
}

export {POST, PUT, DELETE, GET};
