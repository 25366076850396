import styled, {keyframes} from "styled-components";
import useProductDetails from "../../../hooks/useProductDetails";
import {StyledLink} from "../home/KeruxNavbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import {BackIcon} from "../../../Assets/icons";
import {Link} from "react-router-dom";
import useAddToCartButton from "../../../hooks/useAddToCartButton";
import {soldActive} from "../menu/MenuProductsSection";
import {SoldBanner} from "../menu/FoodItemCard";

import {useTranslation} from 'react-i18next'
export const bounceLeft = keyframes`
  0% {
    left: 15px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 15px;
  }
`;
export const GoBackLink = styled(StyledLink)`
  position: absolute;
  left: 15px;
  margin: 30px 0 0;
  font-size: 15px;
  padding: 10px 0;
  z-index: 800;
  animation: ${bounceLeft} 1.2s linear infinite;

  &:before {
    content: "<-- ";
    margin-right: 3px;
  }
`;
const ProductDetails = styled.section`
  //width: 90vw;
  padding: 20px 0 20px;
  margin: 0 auto;
  display: flex;
  max-height: 100%;
  /* max-height: 800px; */
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: 550px) {
    //width: 70vw;
  }
  @media screen and (min-width: 850px) {
    //width: 70vw;
  }

  @media screen and (min-width: 1200px) {
    /* height: 100%; */
    /* max-height: 800px; */
  }

  & > button {
    transform: scale(0.8);
    margin-top: -45px;
    margin-bottom: 10px;
    @media screen and (min-width: 600px) {
      margin-top: -50px;
      transform: scale(1);
    }
  }
`;

const PrductImg = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 50vh;
  border-radius: 0 24px 0 24px;
  /* @media screen and (min-width: 500px) {
    max-height: 350px;
    max-width: 350px;
  } */
`;

function ProductDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const {thisProductInfo, goBackPath, setIsLoaded} = useProductDetails();
  const {handlerAddToCartAndAddToTotalCost, isAdded} = useAddToCartButton(thisProductInfo);

  if (!thisProductInfo) {
    return <></>;
  }

  const originalPrice = thisProductInfo.price;
  const currentPrice = (soldActive && thisProductInfo.sold) ? (thisProductInfo.price * ((1 - thisProductInfo.sold / 100))) : thisProductInfo.price;

  return (
    <ProductDetails>
      <Container className="mb-2">
        <Link to={goBackPath} className="text-decoration-none">
          <Button size="sm" className="px-2 me-1">
            <BackIcon fill="white" width={14}/>
          </Button>
          {t("Retour")}
        </Link>
      </Container>
      <Container>
        <Row>
          <Col xs="12" md="6">
            <div className="position-relative">
              <PrductImg src={thisProductInfo?.img} alt={thisProductInfo?.name} onLoad={() => setIsLoaded(true)}/>
              <SoldBanner reduction={thisProductInfo?.sold}/>
            </div>
          </Col>
          <Col>
            <Container className="d-flex flex-column h-100">
              <h2 className="text-primary fw-semi-bold text-capitalize text-nowrap pt-4 pt-sm-0">
                {t(thisProductInfo?.name)}
                <sup className="text-info h4 ms-1 text-nowrap">{thisProductInfo?.size}</sup>
              </h2>
              <p className="flex-grow-1">{thisProductInfo?.description}</p>

              <h3 className="text-primary fw-normal">
                {currentPrice} {t(" DA")}
                <sup className="text-danger ms-1">
                  <s>{(soldActive && thisProductInfo?.sold) ? (originalPrice + " DA") : ""}</s>
                </sup>
              </h3>
              <Button onClick={() => handlerAddToCartAndAddToTotalCost(
                thisProductInfo,
                thisProductInfo?.sold
              )} className={"px-3 " + (isAdded ? "text-white " : "")}
                      variant={isAdded ? "warning" : "primary"}>
                {isAdded ? "Ajouté au panier" : "Ajouter au panier +"}
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
    </ProductDetails>
  );


// <ProductDetails>
//   <GoBackLink to={goBackPath}> Retour</GoBackLink>
//   <ImageWrapper isLoaded={isLoaded}>
//     <PrductImg src={thisProductInfo?.img} alt={thisProductInfo?.name} onLoad={() => setIsLoaded(true)} />
//   </ImageWrapper>
//   <Line />

//   <div className="row">
//     <div className="col-sm-12 col-md-8">
//       <Name>{thisProductInfo?.name}</Name>
//     </div>
//     <div className="col-sm-12 col-md-4">
//       {" "}
//       <BtnADDLayout>
//         {!isInShoppingCart ? <AddToCartButton thisProductInfo={thisProductInfo} /> : null}
//       </BtnADDLayout>
//     </div>
//   </div>

//   <Line />
//   <dl>
//     <Leyend>Catégorie:</Leyend> <Deltail>{thisProductInfo?.category}</Deltail>
//     <Leyend>Portion:</Leyend> <Deltail>{thisProductInfo?.size}</Deltail>
//     <Leyend>Prix:</Leyend> <Deltail>{thisProductInfo?.price} DA</Deltail>
//     <Leyend>Description:</Leyend> <Description>{thisProductInfo?.description}</Description>
//   </dl>
// </ProductDetails>
}

export default ProductDetailsPage;
