import currentUserAPI from "./currentUserAPI";
import {PUT} from "../utils/https";
import {getCookie} from "../utils/cookies";

async function UploadProfileAPI({
                                  setFormIsLoading,
                                  setIsSuccessfullySend,
                                  setServerError,
                                  info,

                                  setCurrentUser,
                                  history,
                                }) {
  try {
    setFormIsLoading(true);
    setServerError("");

    const id = getCookie("kerux-user-id") // localStorage.getItem("userId");

    const {response, json} = await PUT(`/api/users/me/${id}`, info);

    setFormIsLoading(false);

    if (response.status === 200) {
      await currentUserAPI({setCurrentUser});

      setIsSuccessfullySend(true);
      setTimeout(() => {
        setIsSuccessfullySend(false);

        history.push("/myAccount/myProfile");
      }, 3000);

      return;
    }

    setServerError(json.message);
  } catch (err) {
    console.log(err);
  }
}

export default UploadProfileAPI;
